import { generateItemKeyIndex as generateItemKeyIndexFn } from '@/utils/utils.js';

import { knowledgeTaskPageTypeVal } from '@/enums/knowledge-task-page-type.js';

import { QuestionAnswerOutlined, CheckBoxOutlined } from '@vicons/material';
import conversationScenes1Bg from './imgs/s-1-bg.jpg';
import conversationScenes2Bg from './imgs/s-2-bg.jpg';
import conversationScenes3Bg from './imgs/s-3-bg.jpg';
import conversationScenes4Bg from './imgs/1.jpg';
import conversationScenes5Bg from './imgs/s-5-bg.jpg';
import conversationPeople1Avatar from './imgs/p-1-avatar.png';
import conversationPeople2Avatar from './imgs/p-2-avatar.png';
import conversationPeople3Avatar from './imgs/p-3-avatar.png';
import conversationPeople4Avatar from './imgs/p-4-avatar.png';
import conversationPeople5Avatar from './imgs/p-5-avatar.png';

import scenes1People2Avatar from './imgs/p-2-avatar.png';
import scenes1People3Avatar from './imgs/p-3-avatar.png';
import scenes1People4Avatar from './imgs/p-4-avatar.png';
import scenes1People5Avatar from './imgs/p-5-avatar.png';
import scenes2People2Avatar from './imgs/p-2-avatar.png';
import scenes2People3Avatar from './imgs/p-3-avatar.png';
import scenes2People4Avatar from './imgs/p-4-avatar.png';
import scenes2People5Avatar from './imgs/p-5-avatar.png';
import scenes3People2Avatar from './imgs/p-2-avatar.png';
import scenes3People3Avatar from './imgs/p-3-avatar.png';
import scenes3People4Avatar from './imgs/p-4-avatar.png';
import scenes3People5Avatar from './imgs/p-5-avatar.png';
import scenes4People2Avatar from './imgs/p-2-avatar.png';
import scenes4People3Avatar from './imgs/p-3-avatar.png';
import scenes4People4Avatar from './imgs/p-4-avatar.png';
import scenes4People5Avatar from './imgs/p-5-avatar.png';
import scenes5People2Avatar from './imgs/p-2-avatar.png';
import scenes5People3Avatar from './imgs/p-3-avatar.png';
import scenes5People4Avatar from './imgs/p-4-avatar.png';
import scenes5People5Avatar from './imgs/p-5-avatar.png';

import scenes1People2Pic from './imgs/s-1-p-2.png';
import scenes1People3Pic from './imgs/s-1-p-3.png';
import scenes1People4Pic from './imgs/s-1-p-4.png';
import scenes1People5Pic from './imgs/s-1-p-5.png';
import scenes2People2Pic from './imgs/s-2-p-2.png';
import scenes2People3Pic from './imgs/s-2-p-3.png';
import scenes2People4Pic from './imgs/s-2-p-4.png';
import scenes2People5Pic from './imgs/s-2-p-5.png';
import scenes3People2Pic from './imgs/s-3-p-2.png';
import scenes3People3Pic from './imgs/s-3-p-3.png';
import scenes3People4Pic from './imgs/s-3-p-4.png';
import scenes3People5Pic from './imgs/s-3-p-5.png';
import scenes4People2Pic from './imgs/s-2-p-2.png';
import scenes4People3Pic from './imgs/s-2-p-3.png';
import scenes4People4Pic from './imgs/s-2-p-4.png';
import scenes4People5Pic from './imgs/s-2-p-5.png';
import scenes5People2Pic from './imgs/s-5-p-2.png';
import scenes5People3Pic from './imgs/s-5-p-3.png';
import scenes5People4Pic from './imgs/s-5-p-4.png';
import scenes5People5Pic from './imgs/s-5-p-5.png';

import { resStatusEnum } from '@/enumerators/http.js';
import { getExercises } from '@/api/paper.js';

const { SUCCESS } = resStatusEnum;

export const generateItemKeyIndex = generateItemKeyIndexFn;

export const formItemTypeVal = {
  INPUT: 1,
  TEXTAREA: 2,
  SELECT: 3,
  CHECKBOX_GROUP: 4,
  RADIO_GROUP: 7,
  COMMENT: 5,
  SUBMIT: 6,
  NOTE_PAD: 8
};

export const answerMatchMethodVal = {
  FUZZY_MATCH: 0,
  ACCURATE_MATCH: 1
};
export const answerMatchMethodValName = {
  0: '模糊匹配',
  1: '精准匹配'
};

export const conversationEditBtnTypeVal = {
  SCENES: 1,
  CHAT: 2,
  QUES_ANS: 3
};

export const conversationScenesVal = {
  WORKSTATION: 1,
  MEETING_ROOM: 2,
  MEETING_ROOM_ENTRANCE: 3,
  // OA: 4,
  MANAGER_OFFICE: 5
};
export const conversationScenesValName = {
  1: '工位',
  2: '会议室',
  3: '会议室门口',
  // 4: 'OA',
  5: '经理办公室',
};
export const conversationScenesValBg = {
  1: conversationScenes1Bg,
  2: conversationScenes2Bg,
  3: conversationScenes3Bg,
  4: conversationScenes4Bg,
  5: conversationScenes5Bg
};

const conversationPeopleHumanVal = {
  MA_LI: 2,
  SU_SHAN: 3,
  AI_LUN: 4,
  XIAO_JIN: 5,
};
const conversationPeopleNarrationVal = {
  NARRATION: 1,
  NARRATION_WIN: 11
};
export const conversationPeopleVal = {
  ...conversationPeopleNarrationVal,
  ...conversationPeopleHumanVal
};
export const conversationPeopleHumanValName = {
  2: '玛丽',
  3: '苏珊',
  4: '艾伦',
  5: '小金',
};
export const conversationPeopleNarrationValName = {
  1: '旁白（字幕）',
  11: '旁白（弹窗）'
};
export const conversationPeopleValName = {
  ...conversationPeopleHumanValName,
  ...conversationPeopleNarrationValName
};
export const conversationPeopleValAvatar = {
  1: conversationPeople1Avatar,
  11: conversationPeople1Avatar,
  2: conversationPeople2Avatar,
  3: conversationPeople3Avatar,
  4: conversationPeople4Avatar,
  5: conversationPeople5Avatar
};
export const conversationScenesValPeopleValPeople = {
  '1-2': {
    avatar: scenes1People2Avatar,
    inScenesPic: scenes1People2Pic,
    inScenesStyle: { left: '1293px', top: '473px' },
  },
  '1-3': {
    avatar: scenes1People3Avatar,
    inScenesPic: scenes1People3Pic,
    inScenesStyle: { left: '1440px', top: '492px', 'z-index': '1' },
  },
  '1-4': {
    avatar: scenes1People4Avatar,
    inScenesPic: scenes1People4Pic,
    inScenesStyle: { left: '271px', top: '360px' },
  },
  '1-5': {
    avatar: scenes1People5Avatar,
    inScenesPic: scenes1People5Pic,
    inScenesStyle: { left: '230px', top: '463px', 'z-index': '1' },
  },
  '2-2': {
    avatar: scenes2People2Avatar,
    inScenesPic: scenes2People2Pic,
    inScenesStyle: { left: '195px', top: '563px' },
  },
  '2-3': {
    avatar: scenes2People3Avatar,
    inScenesPic: scenes2People3Pic,
    inScenesStyle: { left: '570px', top: '512px' },
  },
  '2-4': {
    avatar: scenes2People4Avatar,
    inScenesPic: scenes2People4Pic,
    inScenesStyle: { left: '970px', top: '518px' },
  },
  '2-5': {
    avatar: scenes2People5Avatar,
    inScenesPic: scenes2People5Pic,
    inScenesStyle: { left: '1370px', top: '546px' },
  },
  '3-2': {
    avatar: scenes3People2Avatar,
    inScenesPic: scenes3People2Pic,
    inScenesStyle: { left: '419px', top: '406px' },
  },
  '3-3': {
    avatar: scenes3People3Avatar,
    inScenesPic: scenes3People3Pic,
    inScenesStyle: { left: '0', top: '496px' },
  },
  '3-4': {
    avatar: scenes3People4Avatar,
    inScenesPic: scenes3People4Pic,
    inScenesStyle: { left: '139px', top: '368px' },
  },
  '3-5': {
    avatar: scenes3People5Avatar,
    inScenesPic: scenes3People5Pic,
    inScenesStyle: { left: '659px', top: '384px' },
  },
  '4-2': {
    avatar: scenes4People2Avatar,
    inScenesPic: scenes4People2Pic,
    inScenesStyle: {},
  },
  '4-3': {
    avatar: scenes4People3Avatar,
    inScenesPic: scenes4People3Pic,
    inScenesStyle: {},
  },
  '4-4': {
    avatar: scenes4People4Avatar,
    inScenesPic: scenes4People4Pic,
    inScenesStyle: {},
  },
  '4-5': {
    avatar: scenes4People5Avatar,
    inScenesPic: scenes4People5Pic,
    inScenesStyle: {},
  },
  '5-2': {
    avatar: scenes5People2Avatar,
    inScenesPic: scenes5People2Pic,
    inScenesStyle: { left: '1239px', top: '387px' },
  },
  '5-3': {
    avatar: scenes5People3Avatar,
    inScenesPic: scenes5People3Pic,
    inScenesStyle: { left: '604px', top: '434px' },
  },
  '5-4': {
    avatar: scenes5People4Avatar,
    inScenesPic: scenes5People4Pic,
    inScenesStyle: { left: '942px', top: '460px' },
  },
  '5-5': {
    avatar: scenes5People5Avatar,
    inScenesPic: scenes5People5Pic,
    inScenesStyle: { left: '228px', top: '423px' },
  }
};

export const conversationQuesVal = {
  QUES_ANS: 2,
  SELECT: 3
};
export const conversationQuesValName = {
  2: '问答',
  3: '选项'
};
export const conversationQuesValIconComp = {
  2: QuestionAnswerOutlined,
  3: CheckBoxOutlined
};

export const conversationItemTypeVal = { // req res
  CONVERSATION: 1,
  QUES_ANS: 2,
  SELECT: 3
};

export function generateFileData(isCreate, fileData) {
  isCreate = Boolean(isCreate);
  if (!fileData) {
    isCreate = true;
  }
  return isCreate ? {
    fileUrl: '',
    videoId: '',
    name: '',
    size: 0,
    ext: '',
    fileType: ''
  } : {
    fileUrl: fileData.file_url,
    videoId: fileData.video_id,
    name: fileData.file_name,
    size: Number(fileData.file_size),
    ext: fileData.extension,
    fileType: Number(fileData.file_type)
  };
}

export function generateAnsOption(isCreate, data) {
  isCreate = Boolean(isCreate);
  const key = generateItemKeyIndex();
  const obj = isCreate ? {
    key,
    ...data // { label: '选项A', value: 0 }
  } : {
    key,
    ...data
  };
  return obj;
}

export function generateSkillItem(isCreate, data) {
  isCreate = Boolean(isCreate);
  const key = generateItemKeyIndex();
  const obj = isCreate ? {
    key,
    // skillID: null,
    skillID: window.adminKnowledgeTaskSettingSkillID || null,
    score: null
  } : {
    key,
    // skillID: data.skillID,
    skillID: window.adminKnowledgeTaskSettingSkillID || null,
    score: data.score
  };
  return obj;
}

export function generateFormItemData(isCreate, data) {
  isCreate = Boolean(isCreate);
  const key = generateItemKeyIndex();
  let { type, introduce, skill, judge_type } = data;
  let skills = [];
  let answerMatchMethod;
  if (!isCreate) {
    type = Number(type);
    introduce = JSON.parse(introduce);
    if (skill) {
      skill = JSON.parse(skill);
      // skills = Object.keys(skill).map(skillID => generateSkillItem(false, { skillID, score: parseInt(skill[skillID]) }));
      skills = Object.keys(skill)
        .filter(skillID => skillID === window.adminKnowledgeTaskSettingSkillID)
        .map(skillID => generateSkillItem(false, { skillID, score: parseInt(skill[skillID]) }));
      skills.length === 0 && (skills.push(generateSkillItem('isCreate')));
    }
    answerMatchMethod = Number(judge_type);
  }
  const obj = isCreate ? {
    key,
    type,
    answer: null,
    skills: [],
    // error: true,
    // errMsg: '',
    valid: false,
    active: true,
    x: 50,
    y: 50,
    minW: 100,
    // minH: xxx,
    w: 540,
    // h: xxx,
  } : {
    key,
    type,
    valid: true,
    active: false,
    minW: 100
  };

  switch (type) {
    case formItemTypeVal.INPUT:
      Object.assign(obj, isCreate ? {
        answerMatchMethod: null,
        minH: 25,
        h: 40,
      } : {
        answerMatchMethod,
        minH: 25,
        answer: data.ques_answer,
        skills,
        x: introduce.x,
        y: introduce.y,
        w: introduce.w,
        h: introduce.h,
      });
      break;
    case formItemTypeVal.TEXTAREA:
      Object.assign(obj, isCreate ? {
        answerMatchMethod: null,
        minH: 36,
        h: 60,
      } : {
        minH: 36,
        x: introduce.x,
        y: introduce.y,
        w: introduce.w,
        h: introduce.h,
        answerMatchMethod,
        answer: data.ques_answer,
        skills
      });
      break;
    case formItemTypeVal.SELECT:
      Object.assign(obj, isCreate ? {
        minH: 25,
        h: 40,
        options: [
          generateAnsOption('isCreate', { label: '选项A', value: 0 }),
          generateAnsOption('isCreate', { label: '选项B', value: 1 }),
          generateAnsOption('isCreate', { label: '选项C', value: 2 }),
          generateAnsOption('isCreate', { label: '选项D', value: 3 })
        ]
      } : {
        minH: 25,
        x: introduce.x,
        y: introduce.y,
        w: introduce.w,
        h: introduce.h,
        skills,
        answer: Number(data.ques_answer),
        options: data.ques_select.map(item => generateAnsOption(false, { label: item.slice(2), value: Number(item[0]) }))
      });
      break;
    case formItemTypeVal.CHECKBOX_GROUP:
      Object.assign(obj, isCreate ? {
        w: 360,
        minH: 30,
        h: 30,
        options: [
          generateAnsOption('isCreate', { label: '选项A', value: 0 }),
          generateAnsOption('isCreate', { label: '选项B', value: 1 }),
          generateAnsOption('isCreate', { label: '选项C', value: 2 }),
          generateAnsOption('isCreate', { label: '选项D', value: 3 })
        ]
      } : {
        minH: 30,
        x: introduce.x,
        y: introduce.y,
        w: introduce.w,
        h: introduce.h,
        skills,
        answer: data.ques_answer.split('|').map(item => Number(item)),
        options: data.ques_select.map(item => generateAnsOption(false, { label: item.slice(2), value: Number(item[0]) }))
      });
      break;
    case formItemTypeVal.RADIO_GROUP:
      Object.assign(obj, isCreate ? {
        w: 360,
        minH: 30,
        h: 30,
        options: [
          generateAnsOption('isCreate', { label: '选项A', value: 0 }),
          generateAnsOption('isCreate', { label: '选项B', value: 1 }),
          generateAnsOption('isCreate', { label: '选项C', value: 2 }),
          generateAnsOption('isCreate', { label: '选项D', value: 3 })
        ]
      } : {
        minH: 30,
        x: introduce.x,
        y: introduce.y,
        w: introduce.w,
        h: introduce.h,
        skills,
        answer: Number(data.ques_answer),
        options: data.ques_select.map(item => generateAnsOption(false, { label: item.slice(2), value: Number(item[0]) }))
      });
      break;
    case formItemTypeVal.COMMENT:
      Object.assign(obj, isCreate ? {
        answer: '注释...',
        minW: 20,
        w: 20,
        minH: 20,
        h: 20,
        valid: true
      } : {
        minW: 20,
        minH: 20,
        w: 20,
        h: 20,
        x: introduce.x,
        y: introduce.y,
        answer: data.ques_answer
      });
      break;
    case formItemTypeVal.SUBMIT:
      Object.assign(obj, isCreate ? {
        minW: 60,
        w: 100,
        minH: 25,
        h: 40,
        answer: '提交',
        behaviour: null
      } : {
        minW: 60,
        minH: 25,
        x: introduce.x,
        y: introduce.y,
        w: introduce.w,
        h: introduce.h,
        answer: data.ques_answer,
        behaviour: introduce.behaviour
      });
      break;
  }

  return obj;
}
export function generateFormNotePadData(isCreate, data) {
  isCreate = Boolean(isCreate);
  const key = generateItemKeyIndex();
  const obj = {
    key,
    type: formItemTypeVal.NOTE_PAD,
    title: '',
    content: ''
  };
  let title = '';
  let content = '';
  try {
    const introduce = JSON.parse(data.introduce);
    title = introduce.title;
    content = introduce.content;
  } catch (e) {
    console.log(e);
  }
  Object.assign(obj, isCreate ? {} : {
    title,
    content
  });
  return obj;
}

export function generateConversationItem(isCreate, { editBtnType, optionVal, title, ques_select, ques_answer, skill, upload, id }) {
  isCreate = Boolean(isCreate);
  const obj = {
    key: generateItemKeyIndex(),
    editBtnType,
    optionVal,
    savedID: id
  };
  let skills = []; 
  if (!isCreate) {
    if (skill) {
      skill = JSON.parse(skill);
      // skills = Object.keys(skill).map(skillID => generateSkillItem(false, { skillID, score: parseInt(skill[skillID]) }));
      skills = Object.keys(skill)
        .filter(skillID => skillID === window.adminKnowledgeTaskSettingSkillID)
        .map(skillID => generateSkillItem(false, { skillID, score: parseInt(skill[skillID]) }));
      skills.length === 0 && (skills.push(generateSkillItem('isCreate')));
    }
  }
  switch (editBtnType) {
    case conversationEditBtnTypeVal.CHAT:
      Object.assign(obj, isCreate ? {
        talkTxt: '',
        talkFile: generateFileData('isCreate'),
        content: ''
      } : {
        talkTxt: title,
        talkFile: generateFileData(false, upload),
        content: ''
      });
      break;
    case conversationEditBtnTypeVal.QUES_ANS:
      const commonObj = isCreate ? {
        ques: '',
        skills: [
          generateSkillItem('isCreate')
        ]
      } : {
        ques: title,
        skills
      };
      switch (optionVal) {
        case conversationQuesVal.QUES_ANS:
          Object.assign(obj, commonObj, isCreate ? {
            ans: '',
          } : {
            ans: ques_answer
          });
          break;
        case conversationQuesVal.SELECT:
          let ansArr = [];
          if (!isCreate) {
            ansArr = ques_answer.split('|');
          }
          Object.assign(obj, commonObj, isCreate ? {
            options: [
              generateAnsOption('isCreate', { label: 'A', value: '', checked: false }),
              generateAnsOption('isCreate', { label: 'B', value: '', checked: false }),
              generateAnsOption('isCreate', { label: 'C', value: '', checked: false }),
              generateAnsOption('isCreate', { label: 'D', value: '', checked: false })
            ]
          } : {
            options: ques_select.map(item => generateAnsOption(false, { label: item[0], value: item.slice(2), checked: ansArr.includes(item[0]) }))
          });
          break;
      }
      break;
  }

  return obj;
}

export function generateExerciseItemData({
  id,
  type,
  title,
  ques_select,
  ques_answer,
  score,
  upload_list,
  ques_analys
}) {
  return {
    id,
    type,
    title,
    ques_select,
    ques_answer,
    score,
    upload_list,
    ques_analys
  }
}
export function generateExerciseViewItemData({
  id,
  type,
  title,
  ques_select,
  ques_answer,
  score,
  upload_list,
  ques_analys
}) {
  return {
    id,
    type,
    title,
    options: ques_select,
    answer: ques_answer,
    score,
    fileList: upload_list,
    analysis: ques_analys
  }
}
export function exercisesContentExerciseIDtoData(taskData) {
  const pageListLink = taskData.pageList;
  const allExerciseIDArr = [];
  const exerciseIDData = {};
  pageListLink.forEach(({ pageTypeVal, exerciseIDArr }) => {
    if (pageTypeVal === knowledgeTaskPageTypeVal.EXERCISES) {
      allExerciseIDArr.push(...exerciseIDArr);
    }
  });
  if (allExerciseIDArr.length > 0) {
    getExercises({
      'Paper[exercises_ids]': allExerciseIDArr.join(',')
    }).then(({ code, data }) => {
      if (code === SUCCESS && Array.isArray(data)) {
        data.forEach(item => {
          exerciseIDData[item.id] = generateExerciseItemData(item);
        });
        pageListLink.forEach(item => {
          const { pageTypeVal, exerciseIDArr } = item;
          if (pageTypeVal === knowledgeTaskPageTypeVal.EXERCISES) {
            item.exercises = exerciseIDArr.map(id => exerciseIDData[id]);
            delete item.exerciseIDArr;
          }
        });
      }
    }).catch(err => {});
  }
}
export function getExerciseItemDataIdArr(exerciseItemDataArr) {
  return exerciseItemDataArr.map(({ id }) => id);
}

export function generatePageItemData(isCreate, pageTypeVal, data) {
  isCreate = Boolean(isCreate);
  const key = generateItemKeyIndex();
  let skill;
  let skills;
  if (!isCreate) {
    if (data.skill) {
      skill = JSON.parse(data.skill);
      skills = Object.keys(skill).map(skillID => generateSkillItem(false, { skillID, score: parseInt(skill[skillID]) }));
    }
  }
  const obj = {
    key,
    // error: false,
    pageTypeVal
  };
  switch (pageTypeVal) {
    case knowledgeTaskPageTypeVal.PIC_TXT:
      Object.assign(obj, isCreate ? {
        htmlValue: '',
        skills: [
          // generateSkillItem('isCreate')
        ]
      } : {
        htmlValue: data.introduce,
        // skills
        skills: []
      });
      break;
    case knowledgeTaskPageTypeVal.VIDEO:
      Object.assign(obj, isCreate ? {
        videoData: generateFileData('isCreate'),
        skills: [
          // generateSkillItem('isCreate')
        ]
      } : {
        videoData: generateFileData(false, data.upload),
        // skills
        skills: []
      });
      break;
    case knowledgeTaskPageTypeVal.CONVERSATION:
      Object.assign(obj, isCreate ? {
        scenesType: null,
        items: []
      } : {
        scenesType: Number(data.scenesType),
        items: data.items.map(item => {
          let editBtnType;
          let optionVal;
          switch (Number(item.type)) {
            case conversationItemTypeVal.CONVERSATION:
              editBtnType = conversationEditBtnTypeVal.CHAT;
              optionVal = Number(item.people);
              break;
            case conversationItemTypeVal.QUES_ANS:
              editBtnType = conversationEditBtnTypeVal.QUES_ANS;
              optionVal = conversationQuesVal.QUES_ANS;
              break;
            case conversationItemTypeVal.SELECT:
              editBtnType = conversationEditBtnTypeVal.QUES_ANS;
              optionVal = conversationQuesVal.SELECT;
              break;
          }

          return generateConversationItem(
            false,
            {
              editBtnType,
              optionVal,
              ...item
            }
          )
        })
      });
      break;
    case knowledgeTaskPageTypeVal.FORM:
      Object.assign(obj, isCreate ? {
        bgPicURL: '',
        formItems: [],
        notePadArr: []
      } : {
        bgPicURL: data.bgPicURL,
        formItems: data.formItems.filter(({ type }) => Number(type) !== formItemTypeVal.NOTE_PAD).map(item => generateFormItemData(false, item)),
        notePadArr: data.formItems.filter(({ type }) => Number(type) === formItemTypeVal.NOTE_PAD).map(item => generateFormNotePadData(false, item))
      });
      break;
    case knowledgeTaskPageTypeVal.EXERCISES:
      Object.assign(obj, isCreate ? {
        skills: [
          // generateSkillItem('isCreate')
        ],
        exercises: []
      } : {
        // skills,
        skills: [],
        exerciseIDArr: data.source_id.split(','),
        exercises: []
      });
      break;
  }
  return obj;
}
export function generateDefaultTaskData(knowledgeID) {
  return {
    knowledgeID,
    recommendMins: null,
    pageList: []
  }
}

function formatResPageList({ knowledge_first_list, knowledge_dialogue_list, list }) {
  const resPageList = [];
  Array.isArray(knowledge_first_list) && knowledge_first_list.forEach(item => {
    const { sort, upload } = item;
    const index = Number(sort);
    if ((index || index === 0) && index >= 0) {
      resPageList[index] || (resPageList[index] = { bgPicURL: '', formItems: [], type: knowledgeTaskPageTypeVal.FORM });
      const pageData = resPageList[index];
      if (!pageData.bgPicURL && upload && upload.file_url) {
        pageData.bgPicURL = upload.file_url;
      }
      pageData.formItems.push(item);
    }
  });
  Array.isArray(knowledge_dialogue_list) && knowledge_dialogue_list.forEach(item => {
    const { sort, scenes } = item;
    const index = Number(sort);
    if ((index || index === 0) && index >= 0) {
      resPageList[index] || (resPageList[index] = { scenesType: null, items: [], type: knowledgeTaskPageTypeVal.CONVERSATION });
      const pageData = resPageList[index];
      if (!pageData.scenesType) {
        pageData.scenesType = Number(scenes) || null;
      }
      pageData.items.push(item);
    }
  });
  Array.isArray(list) && list.forEach(item => {
    const { sort } = item;
    const index = Number(sort);
    if ((index || index === 0) && index >= 0) {
      resPageList[index] || (resPageList[index] = item);
    }
  });

  return resPageList.filter(item => Boolean(item));
}
export function generateTaskData(knowledgeID, resData, { generatePreviewData } = {}) {
  const { advice_time } = resData;

  const resPageList = formatResPageList(resData);
  
  const pageList = resPageList.map(item => generatePageItemData(false, Number(item.type), item));
  
  generatePreviewData || pageList.forEach(({ pageTypeVal, formItems }) => {
    if (pageTypeVal === knowledgeTaskPageTypeVal.FORM) {
      formItems.forEach((item) => {
        let { type, behaviour } = item;
        if (type === formItemTypeVal.SUBMIT) {
          switch (behaviour) {
            case null:
            case 'next':
            case 'prev':
              break;
            default:
              if (behaviour || behaviour === 0) {
                item.behaviour = pageList[behaviour].key;
              } else {
                item.behaviour = 'next';
              }
          }
        }
      });
    }
  });

  return {
    knowledgeID,
    recommendMins: Number(advice_time) || 0,
    pageList
  };
}